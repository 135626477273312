import { useState, useEffect } from "react";
import Header from "./components/Header";
import Tasks from "./components/Tasks";
import AddTask from "./components/AddTask";


const App = () => {
  const [showAddTask, setShowAddTask] = useState(false);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getTasks = async () => {
      const tasksFromServer = await fetchTasks();
      setTasks(tasksFromServer);
    };

    getTasks();
  }, []);

  // Fetch Tasks
  const fetchTasks = async () => {
    const res = await fetch("http://localhost:6039//all");
    const data = await res.json();
    console.log(res);
    return data;
  };

  // Fetch Task
  const fetchTask = async (id) => {
    const res = await fetch(`http://localhost:6039//all/${id}`);
    const data = await res.json();
    console.log(res);
    return data;
  };

  // Add Task
  const addTask = async (task) => {
    const res = await fetch("http://localhost:6039/create", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(task),
    });

    const data = await res.json();

    console.log(data)

    setTasks([...tasks, data[0]]);
  
  };

  // Delete Task
  const deleteTask = async (id) => {
    const res = await fetch(`http://localhost:6039/task/${id}`, {
      method: "DELETE",
    });
    //We should control the response status to decide if we will change the state or not.
    res.status === 200
      ? setTasks(tasks.filter((task) => task.id !== id))
      : alert("Error Deleting This Task");
  };

  // Toggle Reminder
  const toggleReminder = async (id) => {
    const taskToToggle = await fetchTask(id);
    console.log(taskToToggle[0].status);
    console.log(!taskToToggle[0].status);
    var stat = taskToToggle[0].status == "Not Completed" ? "Completed" : "Not Completed"
    const updTask = { ...taskToToggle[0], status: stat};
    
    console.log(updTask);
    console.log(JSON.stringify(updTask))
    const res = await fetch(`http://localhost:6039/task/${id}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(updTask),
    });

    console.log(res.body)

    const data = await res.json();
    console.log(data)

    setTasks(
      tasks.map((task) =>
        task.id === id ? { ...task, status: data[0].status } : task
      )
    );
  };

  return (
    
      <div className="container">
        <Header
          onAdd={() => setShowAddTask(!showAddTask)}
          showAdd={showAddTask}
        />
            <>
              {showAddTask && <AddTask onAdd={addTask} />}
              {tasks.length > 0 ? (
                <Tasks
                  tasks={tasks}
                  onDelete={deleteTask}
                  onToggle={toggleReminder}
                />
              ) : (
                "No Tasks To Show"
              )}
            </>
      </div>
    
  );
};

export default App;
