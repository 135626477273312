import { FaCheck, FaCircle, FaTimes } from "react-icons/fa";

const Task = ({ task, onDelete, onToggle }) => {
  return (
    <div className={`task`} 
      //  ${!task.reminder && "reminder"}
      // `}
      // onDoubleClick={() => onToggle(task.id)}
      >
      <h3>
        <div onClick={()=> {onToggle(task.id); console.log(task.status);}}>  
        {task.status == "Not Completed" ? (<FaCircle/>) : (<FaCheck/>)}
        </div>
        {task.name}
        <FaTimes
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => onDelete(task.id)}
        />
      </h3>
      <p>{task.day}</p>
    </div>
  );
};

export default Task;
